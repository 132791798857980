import { Timeframe } from "../enums/timeframe.enum";

export class TimeframeHelpers {
    public static timeframeIntraday(timeframe: Timeframe): boolean {
        return timeframe >= 0 && timeframe <= 7;;
    }
    
    public static toTimeframeEnum(timeframeString: string): Timeframe {
        const timeframeUpperCase = timeframeString?.trim()?.toUpperCase() ?? '';
        switch (timeframeUpperCase) {
            case '1M':
            case 'ONEMINUTE': {
                return Timeframe.OneMinute;
            }
            case '2M':
            case 'TWOMINUTE': {
                return Timeframe.TwoMinute;
            }
            case '3M':
            case 'THREEMINUTE': {
                return Timeframe.ThreeMinute;
            }
            case '5M':
            case 'FIVEMINUTE': {
                return Timeframe.FiveMinute;
            }
            case '10M':
            case 'TENMINUTE': {
                return Timeframe.TenMinute;
            }
            case '15M':
            case 'FIFTEENMINUTE': {
                return Timeframe.FifteenMinute;
            }
            case '30M':
            case 'THIRTYMINUTE': {
                return Timeframe.ThirtyMinute;
            }
            case '60M':
            case '1H':
            case 'SIXTYMINUTE': {
                return Timeframe.SixtyMinute;
            }
            case '1D': {
                return Timeframe.Day;
            }
            case '1W': {
                return Timeframe.Week;
            }
            case '1MONTH': {
                return Timeframe.Month;
            }
            default: {
                throw new Error(`timeframe string not supported yet: ${timeframeString}`);
            }
        }
    }
    
    public static getDailyChartTimeFrameCandleSize(timeframe: Timeframe): string {
        switch (timeframe) {
            case Timeframe.Day: {
                return 'day';
            }
            case Timeframe.Week: {
                return 'week';
            }
            case Timeframe.Month: {
                return 'month';
            }
            default: {
                return '';
            }
        }
    }

    public static getIntradayChartTimeFrameMinutePerCandle(timeframe: Timeframe): number {
        switch (timeframe) {
            case Timeframe.OneMinute: {
                return 1;
            }
            case Timeframe.TwoMinute: {
                return 2;
            }
            case Timeframe.ThreeMinute: {
                return 3;
            }
            case Timeframe.FiveMinute: {
                return 5;
            }
            case Timeframe.TenMinute: {
                return 10;
            }
            case Timeframe.FifteenMinute: {
                return 15;
            }
            case Timeframe.ThirtyMinute: {
                return 30;
            }
            case Timeframe.SixtyMinute: {
                return 60;
            }
            default: {
                return 0;
            }
        }
    }
}
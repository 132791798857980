export class TradeWaveSettingModel {
    public maType = 'EMA';
    public cloudNumber = 1;
    public fastPeriod = 8;
    public slowPeriod = 21;

    constructor(cloudNumber: number, fastPeriod: number, slowPeriod: number) {
        this.cloudNumber = cloudNumber;
        this.fastPeriod = fastPeriod;
        this.slowPeriod = slowPeriod;
    }

    public getFastPeriodObjectKey(): string {
        return `${this.maType}${this.fastPeriod}CLOSE`.toLowerCase();
    }

    public getSlowPeriodObjectKey(): string {
        return `${this.maType}${this.slowPeriod}CLOSE`.toLowerCase();
    }

    public getFastPeriodGridRequestKey(): string {
        return `${this.maType} ${this.fastPeriod} CLOSE`;
    }

    public getSlowPeriodGridRequestKey(): string {
        return `${this.maType} ${this.slowPeriod} CLOSE`;
    }
}

import { Connection } from "./connection.client";
import { ChartDataToTradeWaveDataPointModelMapper } from "./mappers/chart-data-to-trade-wave-data-point-model.mapper";
import { ChartDataRequestModel } from "./models/chart-data/chart-data-request.model";
import { ClientResponseModel } from "./models/client-response/client-response.model";
import { IdGenerator } from "./models/helpers/id-generator";
import { TimeframeHelpers } from "./models/helpers/timeframe-helpers";
import { TradeWaveDataPoint } from "./models/trade-waves/trade-wave-data-point.model";
import { TradeWaveRequestModel } from "./models/trade-waves/trade-wave-request.model";
import { TradeWaveResponseModel } from "./models/trade-waves/trade-wave-response.model";
import { TradeWaveSettingsModel } from "./models/trade-waves/trade-wave-settings.model";

export class TradeWaveClient {

    private id =  IdGenerator.NewId();
    
    private readonly preMarketMinutes = 330;
    private readonly postMarketMinutes = 300;

    private tradeWave = new TradeWaveSettingsModel();

    constructor() {}

    public async getSignals(requestModel: TradeWaveRequestModel): Promise<ClientResponseModel<TradeWaveResponseModel>> {
        const response = new ClientResponseModel<TradeWaveResponseModel>();
        const dataPoints = await this.getDataPoints(requestModel);
        if (!dataPoints?.length) {
            response.message = 'No datapoints found!';
            return response;
        }

        const isLong = requestModel.direction?.toLowerCase() === 'long';

        const responseModel = new TradeWaveResponseModel();
        const currentBar = dataPoints[dataPoints.length - 1];
        this.tradeWave.setCurrentBar(currentBar);

        const close = currentBar.close;
        const fastValue = this.tradeWave.getFastValue(requestModel.cloudNumber);
        const slowValue = this.tradeWave.getSlowValue(requestModel.cloudNumber);
        if (fastValue == null || isNaN(fastValue) || slowValue == null || isNaN(slowValue)) {
            response.message = 'No fast value or slow value found!';
            return response;
        }

        const shouldUseCloseCheck = requestModel.closeAboveTop || requestModel.closeAboveBottom;
        if (shouldUseCloseCheck) {
            const closeValueToCheck = requestModel.closeAboveTop ? fastValue : slowValue;
            if (isLong) {
                responseModel.canEnter = fastValue > slowValue && close > closeValueToCheck;
                responseModel.canExit = fastValue < slowValue;
            } else {
                responseModel.canEnter = fastValue < slowValue && close < closeValueToCheck;
                responseModel.canExit = fastValue > slowValue;
            }
        } else {
            if (isLong) {
                responseModel.canEnter = fastValue > slowValue;
                responseModel.canExit = fastValue < slowValue;
            } else {
                responseModel.canEnter = fastValue < slowValue;
                responseModel.canExit = fastValue > slowValue;
            }
        }
        
        responseModel.symbol = requestModel.symbol;
        responseModel.close = close;
        responseModel.fastValue = fastValue;
        responseModel.slowValue = slowValue;

        response.isSuccess = true;
        response.data = responseModel;
        return response;
    } 

    private async getDataPoints(requestModel: TradeWaveRequestModel): Promise<Array<TradeWaveDataPoint>>  {
        const chartDataRequest = new ChartDataRequestModel();
        chartDataRequest.extraRowCount = 1;
        chartDataRequest.symbols = [requestModel.symbol];
        
        chartDataRequest.rowCount = 2;
        const timeframe = TimeframeHelpers.toTimeframeEnum(requestModel.timeframe);
        if (TimeframeHelpers.timeframeIntraday(timeframe)) {
            const minutesPerCandle: any = TimeframeHelpers.getIntradayChartTimeFrameMinutePerCandle(timeframe);
            chartDataRequest.options = {
                timeFrame: 'intraday',
                minutesPerCandle: minutesPerCandle,
            };
            
            if (requestModel.preMarket) {
                chartDataRequest.options.startOffset = this.preMarketMinutes;
            }

            if (requestModel.postMarket) {
                chartDataRequest.options.endOffset = this.postMarketMinutes;
            }
        } else {
            const candleSize: any = TimeframeHelpers.getDailyChartTimeFrameCandleSize(timeframe);
            chartDataRequest.options = {
                timeFrame: 'daily',
                candleSize: candleSize
            };
        }

        for (const setting of this.tradeWave.settings) {
            const value = Math.max(setting.fastPeriod, setting.slowPeriod) * 2;
            if (value > chartDataRequest.extraRowCount) {
                chartDataRequest.extraRowCount = value;
            }

            const fastKey = setting.getFastPeriodGridRequestKey();
            if (!chartDataRequest.gridRequest.some(x => x[0] == fastKey)) {
                chartDataRequest.gridRequest.push([fastKey, []]);
            }

            const slowKey = setting.getSlowPeriodGridRequestKey();
            if (!chartDataRequest.gridRequest.some(x => x[0] == slowKey)) {
                chartDataRequest.gridRequest.push([slowKey, []]);
            }
        }

        try {
            const response = await Connection.getInstance().chartDataClient.getChartData(chartDataRequest);
            if (response.data) {
                const dataPoints = ChartDataToTradeWaveDataPointModelMapper.map(response.data);
                return dataPoints;
            }
        } catch {
        }

        return [];
    }
}
import { TradeWaveDataPoint } from "./trade-wave-data-point.model";
import { TradeWaveSettingModel } from "./trade-wave-setting.model";

export class TradeWaveSettingsModel {
    public settings: Array<TradeWaveSettingModel> = [
        new TradeWaveSettingModel(1, 8, 9),
        new TradeWaveSettingModel(2, 5, 12)
    ];
    
    private currentBar: TradeWaveDataPoint | null = null;

    constructor() {}

    public setCurrentBar(currentBar: TradeWaveDataPoint) {
        this.currentBar = currentBar;
    }

    public getFastValue(cloudNumber: number): number | null {
        const emaCloudSettings = this.settings.find(x => x.cloudNumber == cloudNumber);
        const fastObjKey = emaCloudSettings?.getFastPeriodObjectKey();
        if (fastObjKey) {
            return this.currentBar?.[fastObjKey] ?? null;
        }

        return null;
    }

    public getSlowValue(cloudNumber: number): number | null {
        const emaCloudSettings = this.settings.find(x => x.cloudNumber == cloudNumber);
        const slowObjKey = emaCloudSettings?.getSlowPeriodObjectKey();
        if (slowObjKey) {
            return this.currentBar?.[slowObjKey] ?? null;
        }

        return null;
    }
}
export const enum Timeframe {
    OneMinute = 0,
    TwoMinute = 1,
    ThreeMinute = 2,
    FiveMinute = 3,
    TenMinute = 4,
    FifteenMinute = 5,
    ThirtyMinute = 6,
    SixtyMinute = 7,
    Day = 8,
    Week = 9,
    Month = 10,
}

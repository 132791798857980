
export class TradeWaveDataPoint {
    [prop: string]: any;

    public startTimeT = 0;
    public open = 0;
    public low = 0;
    public high = 0;
    public close = 0;
    public xValue = 0;
    public start?: Date;
    public end?: Date;
}

import { SingleGridResponse } from "../../javascript-api/single-grid-request";
import { TradeWaveDataPoint } from "../models/trade-waves/trade-wave-data-point.model";
import { TIUtilities } from "../utilities/ti-utilities";

export class ChartDataToTradeWaveDataPointModelMapper {
    public static map(rawData: Map<string, SingleGridResponse>): Array<TradeWaveDataPoint> {
        const result: Array<TradeWaveDataPoint> = [];
        if (!rawData?.size) {
            return result;
        }

        for (const [symbol, response] of rawData) {
            let headers: Array<string> = [];
            let countValidRow = 0;
            for (let i = 0; i < response.grid.length; i++) {
                const dataRow = response.grid[i];
                if (i == 0) {
                    headers = dataRow;
                    continue;
                }

                if (dataRow.length && !!dataRow[0]) {
                    const dataPoint = new TradeWaveDataPoint();
                    dataPoint.xValue = countValidRow;
                    countValidRow++;

                    for (let j = 0; j < dataRow.length; j++) {
                        const dataCell = dataRow[j];
                        let objKey = headers[j].split(' ').join('').toLowerCase();
                        if (objKey == 'starttime') {
                            continue;
                        } else if (objKey == 'start') {
                            dataPoint.startTimeT = parseFloat(dataCell);
                            dataPoint.start = TIUtilities.decodeServerTime(dataCell);
                        } else if (objKey == 'end') {
                            dataPoint.endTimeT = parseFloat(dataCell);
                            dataPoint.end = TIUtilities.decodeServerTime(dataCell);
                        } else {
                            dataPoint[objKey] = parseFloat(dataCell);
                        }
                    }

                    result.push(dataPoint);
                }
            }
        }

        return result;
    }
}
import { Connection, } from "../services/connection.client";
import { BrokerOrder } from "./models/broker/broker-order";
import { WorkerMessage, WorkerMessageFunction } from "../server-connection/worker";
import { BrokerPosition } from "./models/broker/broker-position";

export type PaperMessageCallback = (messageType:string, data1:any, data2:any | null) => void;
export class BrokerManagerClient {

    private static s_instance: BrokerManagerClient | null = null;
    private paperMessageCallbacks: PaperMessageCallback[] = [];
    private initialized: boolean = false;
    
    private addPositionResponseResolver: (value: boolean) => void = () => {};

    private constructor() {
      
    }

    public static GetInstance(): BrokerManagerClient {
 
        if (!this.s_instance) 
            this.s_instance = new BrokerManagerClient();
         
         return this.s_instance;
    }

    public Disconnect(){
       
        this.initialized = false;
        this.paperMessageCallbacks.length = 0;

    }

    public Connect(broker: string, paperMessageCallback: PaperMessageCallback )
    {
        if(broker ==='Paper')
        {

            this.paperMessageCallbacks.push(paperMessageCallback);

            if(!this.initialized) {
               //The first subscriber to call Connect Sets the Paper Listener  
               Connection.getInstance().setPaperListener(this.PaperListener.bind(this));
               this.initialized = true;
            }

            //Request Portfolio Callbacks if data already exists 
            Connection.getInstance().sendPaperRequestMessage(WorkerMessageFunction.BrokerPortfolioRequest, null);    

        }
        return;
    }

    public CancelOrder(orderId:string){
        Connection.getInstance().sendPaperRequestMessage(WorkerMessageFunction.BrokerOrderCancel, orderId);
    }

    public SubmitOrder(brokerOrder:BrokerOrder){
        Connection.getInstance().sendPaperRequestMessage(WorkerMessageFunction.BrokerOrderSubmit, brokerOrder);
    }

    public ModifyOrder(brokerOrder:BrokerOrder){
        Connection.getInstance().sendPaperRequestMessage(WorkerMessageFunction.BrokerOrderModify, brokerOrder);
    }
   
    public AddPosition(brokerPosition:BrokerPosition): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
     
            this.addPositionResponseResolver = resolve;
            Connection.getInstance().sendPaperRequestMessage(WorkerMessageFunction.BrokerPositionAdd, brokerPosition);
     
        });
    }

    private PaperListener(workerMessage: WorkerMessage){
            
        let messageType = "";
        
        switch (workerMessage.function){

            case WorkerMessageFunction.BrokerAccounts: messageType = "broker-accounts"; break;
            case WorkerMessageFunction.BrokerAccountAdd: messageType = "broker-account-add"; break;
            case WorkerMessageFunction.BrokerAccountUpdate: messageType = "broker-account-update"; break;
            case WorkerMessageFunction.BrokerAccountsUpdate: messageType = "broker-accounts-update"; break;
            case WorkerMessageFunction.BrokerPositions: messageType = "broker-positions"; break;
            case WorkerMessageFunction.BrokerPositionAdd: messageType = "broker-position-add"; break;
            case WorkerMessageFunction.BrokerPositionAddResponse:
             { 
                this.addPositionResponseResolver(workerMessage.message as boolean);
                return;    
            }
            case WorkerMessageFunction.BrokerPositionUpdate: messageType = "broker-position-update"; break;
            case WorkerMessageFunction.BrokerPositionsUpdate: messageType = "broker-positions-update"; break;

            case WorkerMessageFunction.BrokerOrders: messageType = "broker-orders"; break;
            case WorkerMessageFunction.BrokerOrderAdd: messageType = "broker-order-add"; break;
            case WorkerMessageFunction.BrokerOrderUpdate: messageType = "broker-order-update"; break;

            case WorkerMessageFunction.BrokerExecutions: messageType = "broker-executions"; break;
            case WorkerMessageFunction.BrokerExecutionsAdd: messageType = "broker-executions-add"; break;
            case WorkerMessageFunction.BrokerSymbolBars: messageType = "symbol-bars"; break;

        } 

        this.paperMessageCallbacks.forEach(pmc => {
          
            pmc(messageType, workerMessage.message[0], workerMessage.message.length > 1 ? workerMessage.message[1] : null );
          
        });
    }
}
